import React, { Component } from "react";
import { Space, Modal, Form, Input, notification, Button, Divider } from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  MailOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { FormInstance } from "antd/lib/form";
import { StoreState, action } from "./store";
import { connect, ConnectedProps } from "react-redux";
import { GoogleLogin } from "react-google-login";
import styles from "./LoginModal.module.css";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

type Values = {
  email: string;
  password: string;
  confirmPass: string;
};

const mapStateToProps = (state: StoreState) => ({
  open: state.signup.showModal,
  loading: state.signup.apiState === "loading",
  messageType: (state.signup.apiState === "success"
    ? "success"
    : state.signup.apiState === "error"
    ? "error"
    : "none") as "none" | "success" | "error",
  message: state.signup.apiMessage,
});

const mapDispatchToProps = {
  hideModal: action.signup.hideModal,
  doSignup: action.signup.doSignup,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  doNotUseMe?: never;
}

interface State {
  visibleSubmit: boolean;
  gLogin: boolean;
  googleLoginDisable: boolean;
}

class SignupModal extends Component<Props, {}> {
  state: State = {
    visibleSubmit: false,
    gLogin: false,
    googleLoginDisable: false,
  };

  _formRef = React.createRef<FormInstance<Values>>();

  handleOk = async (response?: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.signupModal4
    );

    if (this._formRef.current) {
      if (this.state.gLogin === true) {
        if (this.props.messageType !== "success") {
          this.props.doSignup({
            admin_email: response.profileObj.email,
            password: null,
            google_login: true,
            google_secret_key: response.tokenId,
          });
        } else {
          this.props.hideModal();
        }
      } else {
        const values = (await this._formRef.current.validateFields()) as Values;

        this.props
          .doSignup({
            admin_email: values.email,
            password: values.password,
          })
          .then(() => {
            this.props.hideModal();
          });
      }
    }
  };

  handleCancel = (e: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.signupModal5
    );

    this.props.hideModal();
  };

  responseGoogle = (response: any) => {
    if (Object.keys(response).length > 2) {
      this.setState(
        {
          gLogin: true,
        },
        () => {
          this.handleOk(response);
        }
      );
    } else {
      this.setState({
        visibleSubmit: true,
      });
    }
  };

  handleFailAuthGoogle = (response: any) => {
    let errorMsg = "";
    if (response && response.error === "popup_closed_by_user") {
      this.setState({ googleLoginDisable: false });
    }
    if (response && response.error === "idpiframe_initialization_failed") {
      this.setState({ googleLoginDisable: true });
      errorMsg =
        "Oops. Google login is not available. Check if you are in incognito mode";
      notification.error({ message: errorMsg });
    } else {
      errorMsg = "Signup unsuccessfull";
      notification.error({ message: errorMsg });
    }
  };

  onFocus = (event: any) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
    this.setState({
      gLogin: false,
    });
  };

  render() {
    return (
      <Modal
        title="Sign up"
        width={420}
        confirmLoading={this.props.loading}
        visible={this.props.open}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        okButtonProps={{ size: "large" }}
        cancelButtonProps={{ size: "large" }}
        cancelText={this.props.messageType === "success" ? "Close" : "Cancel"}
        okText={
          !this.state.visibleSubmit
            ? this.props.messageType === "error"
              ? "Retry"
              : "Sign Up"
            : null
        }
        // style={{
        //   display: "flex",
        //   justifyContent: "center",
        //   alignItems: "center",
        // }}
        destroyOnClose
        centered
        maskClosable={false}
      >
        <Form
          ref={this._formRef}
          style={{ marginLeft: 30, marginRight: 30 }}
          autoComplete="off"
        >
          <Space direction="vertical">
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as any}
              // tslint:disable-next-line: jsx-no-lambda
              render={(renderProps) => (
                <Button
                  className={styles.submitBtn}
                  size="large"
                  // loading={this.props.loading}
                  disabled={this.state.googleLoginDisable}
                  style={{ marginTop: "7px" }}
                  onClick={renderProps.onClick}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      style={{
                        marginRight: "10px",
                      }}
                      preserveAspectRatio="xMidYMid"
                      viewBox="0 0 256 400"
                    >
                      <path
                        fill="#4285F4"
                        d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                      />
                      <path
                        fill="#34A853"
                        d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                      />
                      <path
                        fill="#FBBC05"
                        d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                      />
                      <path
                        fill="#EB4335"
                        d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                      />
                    </svg>
                    <p
                      style={{
                        justifySelf: "center",
                      }}
                    >
                      Sign up with Google{" "}
                    </p>
                  </div>
                </Button>
              )}
              onSuccess={this.responseGoogle}
              onFailure={this.handleFailAuthGoogle}
              cookiePolicy={"single_host_origin"}
            />
            <Divider plain>OR</Divider>
            <Form.Item
              name="email"
              label="Organization email"
              style={{ fontWeight: 700 }}
              rules={[
                {
                  required: true,
                  message: "Please enter a valid email.",
                  type: "email",
                },
              ]}
            >
              <Input
                placeholder="Email id"
                allowClear
                size="large"
                prefix={<MailOutlined />}
                autoComplete="off"
                onFocus={this.onFocus}
                autoFocus
                readOnly={this.props.loading}
              />
            </Form.Item>
            <Form.Item
              hasFeedback
              name="password"
              label="Password"
              style={{ fontWeight: 700 }}
              rules={[
                {
                  required: true,
                  message: "Password is required.",
                },
              ]}
            >
              <Input.Password
                placeholder="Create password"
                prefix={<LockOutlined />}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                size="large"
                autoComplete="off"
                onFocus={this.onFocus}
                readOnly={this.props.loading}
              />
            </Form.Item>
            <Form.Item
              hasFeedback
              name="confirmPass"
              label="Confirm"
              style={{ fontWeight: 700 }}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value, cb) {
                    if (!value || getFieldValue("password") === value) {
                      return cb();
                    }
                    return cb("Passwords should match");
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Confirm password"
                prefix={<LockOutlined />}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                size="large"
                autoComplete="off"
                onFocus={this.onFocus}
                readOnly={this.props.loading}
              />
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    );
  }
}

export default connector(SignupModal);
