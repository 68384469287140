import { fetchUtil, fetchTableExportBlob } from "./common";
import { PickupItem } from "~/fragment/pickup-field/pickupSlice";
import { shallowParseDate, parseNumber, roundNumber } from "~/lib";
import {
  Expense,
  ExpenseSummary,
  PaymentDetails,
} from "~/feature/expense/ExpenseModal";
import format from "date-fns/format/index";

export enum PaymentMode {
  CASH = 1,
  OTHER = 2,
}

export enum PaymentStatus {
  NOT_DONE = 0,
  DONE = 1,
  PARTIAL = 2,
}

const _parseStringNumbers = (expenseAPIData: Expense): Expense => {
  expenseAPIData.amount = String(+expenseAPIData.amount!);
  expenseAPIData.tds_amount = String(expenseAPIData.tds_amount)
    ? +expenseAPIData.tds_amount
    : 0;
  expenseAPIData.igst = String(expenseAPIData.igst)
    ? String(+expenseAPIData.igst)
    : String(0);
  expenseAPIData.cgst = String(expenseAPIData.cgst)
    ? String(+expenseAPIData.cgst)
    : String(0);
  expenseAPIData.sgst = expenseAPIData.sgst ? +expenseAPIData.sgst : 0;
  expenseAPIData.total_pay_amount = expenseAPIData.total_pay_amount
    ? +expenseAPIData.total_pay_amount
    : 0;
  expenseAPIData.net_total = +expenseAPIData.net_total;
  return expenseAPIData;
};

export const getTableExportBlob = async (
  page_no: number,
  time_zone_offset: number,
  no_of_rows: number,
  month: string,
  fy: string,
  sort_param: string,
  acceptType: string,
  selectedFields: string
): Promise<{
  ok: boolean;
  blob: Blob | null | undefined;
  message: string;
}> => {
  const { ok, blob, message } = await fetchTableExportBlob(
    "POST",
    "/get_expense",
    {
      fy,
      month,
      page_no,
      no_of_rows,
      time_zone_offset,
      sort_param,
    },
    acceptType,
    selectedFields
  );
  if (!ok) {
    return { ok, blob: null, message };
  } else {
    return {
      ok,
      blob,
      message,
    };
  }
};

export const getPurDebitNoteByID = async (
  p_debit_note_id: number
): Promise<{
  ok: boolean;
  message: string;
  data?: any;
}> => {
  const time_zone_offset = new Date().getTimezoneOffset();
  const { ok, message, json } = await fetchUtil("POST", "/get_pur_debit_note", {
    p_debit_note_id,
  });
  return {
    ok: ok && json.length > 0,
    message: message || "No details found. Please check id",
    data: json.length > 0 ? shallowParseDate(json[0]) : undefined,
  };
};

export const getPurDebitNoteByExpenseID = async (
  p_debit_note_id: number
): Promise<{
  ok: boolean;
  message: string;
  data?: any;
}> => {
  const time_zone_offset = new Date().getTimezoneOffset();
  const { ok, message, json } = await fetchUtil(
    "POST",
    "/get_pur_debit_note_expense",
    {
      expense_id: p_debit_note_id,
      time_zone_offset,
    }
  );
  return {
    ok: ok && json.length > 0,
    message: message || "No details found. Please check id",
    data: json.length > 0 ? shallowParseDate(json[0]) : undefined,
  };
};

export const getSalerDebitNoteByID = async (
  s_debit_note_id: number
): Promise<{
  ok: boolean;
  message: string;
  data?: any;
}> => {
  const { ok, message, json } = await fetchUtil(
    "POST",
    "/get_seller_debit_note",
    {
      s_debit_note_id,
    }
  );
  return {
    ok: ok && json.length > 0,
    message: message || "No details found. Please check id",
    data: json.length > 0 ? shallowParseDate(json[0]) : undefined,
  };
};
export const getSalerDebitNoteByExpenseID = async (
  s_debit_note_id: number
): Promise<{
  ok: boolean;
  message: string;
  data?: any;
}> => {
  const { ok, message, json } = await fetchUtil(
    "POST",
    "/get_seller_debit_note_expense",
    {
      expense_id: s_debit_note_id,
      time_zone_offset: 330,
    }
  );
  return {
    ok: ok && json.length > 0,
    message: message || "No details found. Please check id",
    data: json.length > 0 ? shallowParseDate(json[0]) : undefined,
  };
};

export const getExpenseSummary = async (
  month: any,
  fy: number,
  search_text?: string
): Promise<{
  ok: boolean;
  message: string;
  data?: ExpenseSummary;
}> => {
  const { ok, message, json } = await fetchUtil(
    "POST",
    "/get_expense_summary",
    {
      month,
      fy,
      search_text,
    }
  );
  if (!ok) {
    return { ok, message };
  } else if (json.length === 0) {
    return { ok: false, message: "No data found for selected month/year" };
  } else {
    const data = json[0];
    data.expense_amount = parseNumber(data.expense_amount as string);
    return { ok, message, data: json[0] as ExpenseSummary };
  }
};
export const getTxnAmount = async (
  customer_id: number,
  receipt_date: Date
): Promise<{
  ok: boolean;
  message: string;
  data?: any;
}> => {
  const { ok, message, json } = await fetchUtil(
    "POST",
    "/get_txn_amount_vendor_fy",
    {
      customer_id,
      receipt_date,
    }
  );
  if (!ok) {
    return { ok, message };
  } else {
    return { ok, message, data: json };
  }
};

export const getPurDebitSummary = async (
  month: any,
  fy: number
): Promise<{
  ok: boolean;
  message: string;
  data?: ExpenseSummary;
}> => {
  const { ok, message, json } = await fetchUtil(
    "POST",
    "/get_pur_debit_note_summary",
    {
      month,
      fy,
    }
  );
  if (!ok) {
    return { ok, message };
  } else if (json.length === 0) {
    return { ok: false, message: "No data found for selected month/year" };
  } else {
    const data = json[0];
    data.expense_amount = parseNumber(data.expense_amount as string);
    return { ok, message, data: json[0] as ExpenseSummary };
  }
};

export const getSelDebitSummary = async (
  month: any,
  fy: number
): Promise<{
  ok: boolean;
  message: string;
  data?: ExpenseSummary;
}> => {
  const { ok, message, json } = await fetchUtil(
    "POST",
    "/get_seller_debit_note_summary",
    {
      month,
      fy,
    }
  );
  if (!ok) {
    return { ok, message };
  } else if (json.length === 0) {
    return { ok: false, message: "No data found for selected month/year" };
  } else {
    const data = json[0];
    data.expense_amount = parseNumber(data.expense_amount as string);
    return { ok, message, data };
  }
};

export const getExpenseList = async (
  page_no: number,
  time_zone_offset: number,
  no_of_rows: number,
  month: string,
  fy: string,
  sort_param: string,
  search_text?: string
): Promise<{
  ok: boolean;
  data?: Array<Expense>;
  message: string;
  schema?: Array<any>;
}> => {
  const { ok, json, message, schema } = await fetchUtil(
    "POST",
    "/get_expense",
    {
      fy,
      month,
      page_no,
      no_of_rows,
      time_zone_offset,
      sort_param,
      search_text,
    }
  );

  if (!ok) {
    return { ok, data: [], message };
  } else {
    const data = json as Array<Expense>;
    const paymentDetailsResponses = await Promise.all(
      data
        .filter((it) => it.payment_receipts_id !== null)
        .map((it) => getPaymentDetais(it.id))
    );
    data.forEach((expense) => {
      // parse dates
      _parseStringNumbers(expense);
      expense.receipt_date = new Date(expense.receipt_date as any);
      expense.paid_time = new Date(expense.paid_time as any);
      expense.crt_time = new Date(expense.crt_time as any);
      expense.update_time = new Date(expense.update_time as any);
      if (
        expense.tax_inclusion === "OutOfTax" ||
        expense.tax_inclusion === "ExclusiveOfTax"
      ) {
        expense.subTotal = expense.amount || 0;
      } else if (expense.tax_inclusion === "InclusiveOfTax") {
        const netTotal = expense.net_total || 0;
        const gst =
          (expense.igst ? +expense.igst : 0) +
          (expense.sgst ? +expense.sgst : 0) +
          (expense.cgst ? +expense.cgst : 0);
        // subTotal = net_total - gst_amount
        expense.subTotal = +netTotal - gst;
      }
      const { payment_receipts_id } = expense;
      if (payment_receipts_id === null) {
        expense.paymentStatus = "Pending";
      } else {
        const detailsResponse = paymentDetailsResponses.find(
          (it) => it.payment_receipts_id === payment_receipts_id
        )!;
        expense.extra = {
          paymentDetails: detailsResponse,
        };
        const paidAmount = detailsResponse.payment_history.reduce(
          (accumulation: number, history) => {
            if (history.payment_status === "Cancelled") {
              return accumulation;
            } else {
              return accumulation + history.paid_amount;
            }
          },
          0
        );
        const totalPayNumber = roundNumber(expense.total_pay_amount);

        if (totalPayNumber - paidAmount === 0) {
          expense.paymentStatus = "Completed";
        } else if (paidAmount > 0) {
          expense.paymentStatus = "Partialy paid/Cancelled";
        } else if (totalPayNumber < paidAmount) {
          expense.paymentStatus = "Over paid";
        }
      }
    });
    return { ok, message, data, schema };
  }
};

export const getPurDebitList = async (
  page_no: number,
  time_zone_offset: number,
  no_of_rows: number,
  month: string,
  fy: string,
  sort_param: string
): Promise<{
  ok: boolean;
  data?: Array<Expense>;
  message: string;
  schema?: Array<any>;
}> => {
  const { ok, json, message, schema } = await fetchUtil(
    "POST",
    "/get_pur_debit_note_list",
    {
      fy,
      month,
      page_no,
      no_of_rows,
      time_zone_offset,
      sort_param,
    }
  );

  if (!ok) {
    return { ok, data: [], message };
  } else {
    // console.log(json)
    const data = json as Array<Expense>;
    return { ok, message, data, schema };
  }
};

export const getSellerDebitNoteList = async (
  page_no: number,
  time_zone_offset: number,
  no_of_rows: number,
  month: string,
  fy: string,
  sort_param: string
): Promise<{
  ok: boolean;
  data?: Array<Expense>;
  message: string;
  schema?: Array<any>;
}> => {
  const { ok, json, message, schema } = await fetchUtil(
    "POST",
    "/get_seller_debit_note_list",
    {
      fy,
      month,
      page_no,
      no_of_rows,
      time_zone_offset,
      sort_param,
    }
  );

  if (!ok) {
    return { ok, data: [], message };
  } else {
    // console.log(json)
    const data = json as Array<Expense>;
    return { ok, message, data, schema };
  }
};

// TODO: qs: will results Array always contains one object ?
export const getPaymentDetais = (
  expense_id: number
): Promise<PaymentDetails> => {
  return fetchUtil("POST", "/get_payment_details", {
    expense_id,
  }).then(({ json }) => {
    const data = json[0];
    if (Array.isArray(data?.payment_history)) {
      for (const entry of data.payment_history) {
        entry.payment_date = new Date(entry.payment_date);
        entry.payment_notes = entry.payment_notes || "";
      }
      data.payment_history.sort(
        (a: any, b: any) => a.payment_date - b.payment_date
      );
    }
    return data;
  });
};

export const getExpenseByID = async (expense_id: number, isCopy?: boolean) => {
  const time_zone_offset = new Date().getTimezoneOffset();
  const { ok, message, json } = await fetchUtil(
    "POST",
    "/get_expense_details",
    {
      expense_id,
      time_zone_offset,
    }
  );
  let data: Expense | undefined;
  if (json.length > 0) {
    // console.log(json[0].row_data[0]);

    const expense = shallowParseDate(_parseStringNumbers(json[0]));
    data = expense;

    const { payment_receipts_id } = expense;
    if (payment_receipts_id === null) {
      expense.paymentStatus = "Pending";
    } else {
      if (!isCopy) {
        const detailsResponse = await getPaymentDetais(expense_id);
        expense.extra = { paymentDetails: detailsResponse };
        const paidAmount = detailsResponse.payment_history.reduce(
          (accumulation: number, history) => {
            if (history.payment_status === "Cancelled") {
              return accumulation;
            } else {
              return accumulation + history.paid_amount;
            }
          },
          0
        );
        const totalPayNumber = roundNumber(
          +expense.net_total * expense.conversion_rate! -
            (expense.tds_amount
              ? +expense.tds_amount * expense.conversion_rate!
              : 0)
        );
        if (
          totalPayNumber - paidAmount === 0 ||
          totalPayNumber - paidAmount < 0
        ) {
          expense.paymentStatus = "Completed";
        } else if (paidAmount > 0) {
          expense.paymentStatus = "Partialy paid/Cancelled";
        } else if (totalPayNumber < paidAmount) {
          expense.paymentStatus = "Over paid";
        }
      }
    }
  }
  // console.log(data);
  return {
    ok: ok && json.length > 0,
    message: message || "No details found. Please check id",
    data,
  };
};

export const saveNewExpense = async (payload: Expense) => {
  const { ok, message, json } = await fetchUtil(
    "POST",
    "/save_expense_details",
    payload
  );
  const data = json[0] as { expense_id: number };
  return { ok, message, data };
};

export const saveSalerdebit = async (payload: Expense) => {
  const { ok, message, json } = await fetchUtil(
    "POST",
    "/save_seller_debit_note",
    payload
  );
  const data = json[0] as { s_debit_note_id: number };
  return { ok, message, data };
};

export const saveNewPurchesDebitNote = async (payload: Expense) => {
  const { ok, message, json } = await fetchUtil(
    "POST",
    "/save_pur_debit_note",
    payload
  );
  const data = json[0] as { p_debit_note_id: number };
  return { ok, message, data };
};

export const editExpenseDetails = async (id: number, payload: Expense) => {
  (payload as any).expense_id = payload.id;
  const { ok, message } = await fetchUtil("POST", "/edit_expense", payload);
  return { ok, message, data: null };
};

export const editPurchesDetails = async (id: number, payload: Expense) => {
  // (payload as any).expense_id = payload.id;
  const { ok, message } = await fetchUtil(
    "POST",
    "/edit_pur_debit_note",
    payload
  );
  return { ok, message, data: null };
};
export const editSalerDetails = async (id: number, payload: Expense) => {
  // (payload as any).expense_id = payload.id;
  const { ok, message } = await fetchUtil(
    "POST",
    "/edit_seller_debit_note",
    payload
  );
  return { ok, message, data: null };
};

export type GSTRate = {
  id: number;
  description: string;
  rate: number;
};

export type CESSRateItem = {
  id: number;
  rate: string;
  cessPercentage: number;
};

/**
 * used only in old ExpenseDetailsPage class component
 * @deprecated
 */
export type CESSRate = {
  id: number;
  label: string;
  value: number;
};

export const getGSTRates = async (): Promise<{
  ok: boolean;
  message: string;
  data?: Array<GSTRate>;
}> => {
  const { ok, message, json } = await fetchUtil("GET", "/get_gst_rates");
  if (!ok) {
    return { ok, message };
  } else {
    return { ok, message, data: json };
  }
};

export const getCurrency = async (
  symbol_name: string,
  receipt_date: Date
): Promise<{
  ok: boolean;
  message: string;
  data?: any;
}> => {
  const { ok, message, json } = await fetchUtil(
    "GET",
    `/conversion_rate?format=1&from=INR&to=${symbol_name}&billDate=${
      receipt_date
        ? format(new Date(receipt_date), "yyyy-MM-dd")
        : format(new Date(), "yyyy-MM-dd")
    }`
  );
  if (!ok) {
    return { ok, message };
  } else {
    return { ok, message, data: json };
  }
};

export const getCessRates = async (): Promise<{
  ok: boolean;
  message: string;
  data?: Array<CESSRateItem>;
}> => {
  const { ok, message, json } = await fetchUtil("GET", "/get_cess_rates");
  if (!ok) {
    return { ok, message };
  } else {
    // we parse rate string e.g. "160%" into rate percentage e.g. 160
    json.forEach((it) => {
      it.cessPercentage = parseInt(it.rate, 10);
    });
    return { ok, message, data: json };
  }
};

export const getUnits = async (): Promise<{
  ok: boolean;
  message: string;
  data?: Array<any>;
}> => {
  const { ok, message, json } = await fetchUtil("GET", "/get_units");
  if (!ok) {
    return { ok, message };
  } else {
    // we parse rate string e.g. "160%" into rate percentage e.g. 160
    // json.forEach((it) => {
    //   it.cessPercentage = parseInt(it.rate, 10);
    // });
    return { ok, message, data: json };
  }
};

export const getExpenseCategories = async (): Promise<{
  ok: boolean;
  message: string;
  data: Array<PickupItem>;
}> => {
  const { ok, message, json } = await fetchUtil(
    "POST",
    "/get_expense_category",
    {
      page_no: 1,
      no_of_rows: 2000,
      search_str: "",
      sort_param: "",
    }
  );

  if (!ok) {
    return { ok, message, data: [] };
  } else {
    return {
      ok,
      message,
      data: json.map((it) => ({
        value: it.id,
        label: it.category,
        category_type: it.category_type,
      })),
    };
  }
};

export const payConfirm = (data: {
  bank_id: number;
  expense_id: number;
  notes: string;
  paid_amount: number;
  pay_amount: number;
  payment_date: string;
  payment_mode: number;
  status: number;
  tds_amount: number;
  tds_rate: number;
  transaction_id: number;
}) => {
  return fetchUtil("POST", "/update_expense/accept_expense", data);
};

export const tdsPayConfirm = (data: {
  expense_id: number;
  tds_amount: number;
  tds_rate: number;
}) => {
  return fetchUtil("POST", "/log_tds", data);
};

export const cancelPayment = (receipt_id: number, expenseId: number) => {
  return fetchUtil("POST", "/cancel_payment", {
    payment_receipt_id: receipt_id,
    expense_id: expenseId,
  });
};

export const cancelPaymentInvoice = (receipt_id: number, invoiecId: number) => {
  return fetchUtil("POST", "/cancel_payment", {
    payment_receipt_id: receipt_id,
    invoice_id: invoiecId,
  });
};
