import { Button, Layout, Menu, notification, Tooltip } from "antd";
import {
  MenuInfo,
  OpenEventHandler as MenuOpenEventHandler,
} from "rc-menu/es/interface";
import React, { Component, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import AddClientDrawer from "~/component/AddClientDrawer";
import CompanyListModal from "~/component/CompanyListModal";
import FirstNameModal from "~/component/FirstNameModal";
import Tawkto from "~/component/Tawkto";
import { default as ComplianceIcon } from "~/component/imt-icon/Compliance";
import { default as DashboardIcon } from "~/component/imt-icon/Dashboard";
import { default as EmployeeIcon } from "~/component/imt-icon/Employee";
import { default as ExpenseIcon } from "~/component/imt-icon/Expense";
import { IMTIconElement } from "~/component/imt-icon/interface";
import { default as InvoiceIcon } from "~/component/imt-icon/Invoice";
import { default as purEx } from "~/component/imt-icon/NorEx";
import { default as PayrollIcon } from "~/component/imt-icon/Payroll";
import Purdb from "~/component/imt-icon/Purdb";
import { default as ReimbursementIcon } from "~/component/imt-icon/Reimbursement";
import SalesCredit from "~/component/imt-icon/SalesCredit";
import SalesDebit from "~/component/imt-icon/SalesDebit";
import { default as SalesIn } from "~/component/imt-icon/SalesInvoice";
import Selldb from "~/component/imt-icon/Selldb";
import { default as SettingIcon } from "~/component/imt-icon/Setting";
import { default as VendorIcon } from "~/component/imt-icon/VendorNew";
import { default as MasterIcon } from "~/component/imt-icon/Master";
import { default as expCatIcon } from "~/component/imt-icon/ExpenseCat";
import { default as proMasIcon } from "~/component/imt-icon/ProductMan";
import { default as PaymentIcon } from "~/component/imt-icon/Payment";
import ChangePasswordDialog from "./changePasswordModal";

import pkg from "../../../package.json";

import {
  AuditOutlined,
  InsertRowLeftOutlined,
  KeyOutlined,
  LockOutlined,
  LogoutOutlined,
  SyncOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { connect, ConnectedProps } from "react-redux";
import { registerEvent } from "~/analytics";
import { changePassword, LoginRole } from "~/api/auth";
import { fetchUtil } from "~/api/common";
import { action, AuthState, StoreDispatch } from "~/app/MainApp/store";
import FieldInfo from "~/component/FieldInfo";
import {
  // EMAIL_VALIDATION_REGEX,
  PASSWORD_VALIDATION_REGEX,
} from "~/lib/constants";
import styles from "./MainLayout.module.css";

import * as ga from "~/contants/gaConstants";

const { Sider } = Layout;

const mapStateToProps = (state: AuthState) => {
  const user = state.mainAppSlice.user;
  const userNameOrEmail = user.first_name ?? user.email ?? "";
  const userFirstChar = userNameOrEmail.substr(0, 1).toUpperCase();
  const companyCount = user.added_clients ?? 0;
  const { showSyncButtonTour } = state.mainAppSlice;
  const { updateCompanyList } = state.mainAppSlice;
  return {
    userDetail: user,
    steps: state.mainAppSlice.steps,
    activeRole: user.activeRole,
    userFirstChar,
    companyCount,
    showSyncButtonTour,
    isSyncing: state.modalSlice.isSyncing,
    syncInterval: state.modalSlice.syncInterval,
    currentTourStep: state.mainAppSlice.currentTourStep,
    currentStepKey: showSyncButtonTour
      ? state.mainAppSlice.steps[state.mainAppSlice.currentTourStep]?.key
      : "",
    updateCompanyList,
  };
};
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  empdetail: (a: any) => dispatch(action.auth.empDetailsSaved(a)),
  dochange: (company_id: any, role_id?: any) =>
    dispatch(action.auth.doChangeCompany(company_id)),
  setActiveRole: (role: any) => dispatch(action.auth.setActiveRole(role)),
  updateFirstLastName: (values: any) =>
    dispatch(action.auth.updateFirstLastName(values)),
  handleUpdateCompanyList: (val: boolean) => {
    dispatch(action.auth.handleUpdateCompanyList(val));
  },
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends RouteComponentProps, PropsFromRedux {
  children: ReactNode;
}
interface State {
  selectedMenu: string;
  showPasswordModal: boolean;
  passLoader: boolean;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  email: string;
  visibleSync?: boolean;
  isSync?: boolean;
  nameModalVisible?: boolean;
  companyListModalVisible: boolean;
  updateList: boolean;
  openMenuKeys: Array<string>;
}

type MenuLink = {
  key: string;
  Icon: IMTIconElement;
  title: ((props: Props) => string) | string;
  displayName: ((props: Props) => string) | string;
  domAttributes?: any;
  subMenuInvoice?: any;
  subMenuExpense?: any;
  subMenuMaster?: any;
};

const EMPLOYEE_MENU_KEYS = ["employee", "payroll", "reimbursement"];
const user = localStorage.getItem("imt__user");
const userData = JSON.parse(user!);

// const userData = localStorage.getItem("imt__user");
// const userParseData = JSON.parse(userData!);

class MainLayout extends Component<Props, State> {
  public static menuLinksUpper: Array<MenuLink> = [
    {
      key: "dashboard",
      Icon: DashboardIcon,
      title: "Dashboard",
      displayName: "Dashb.",
    },
    {
      key: "invoice",
      Icon: InvoiceIcon,
      title: "Invoice",
      displayName: "Sales",
      subMenuInvoice: {
        in: SalesIn,
        cn: SalesCredit,
        dn: SalesDebit,
      },
    },
    {
      key: "expense",
      Icon: ExpenseIcon,
      title: "Expense",
      displayName: "Purchase",
      subMenuExpense: {
        ex: purEx,
        sdb: Selldb,
        pdb: Purdb,
      },
    },

    {
      key: "masters",
      Icon: MasterIcon,
      title: "Masters",
      displayName: "Masters",
      subMenuMaster: {
        vdIcon: VendorIcon,
        pm: proMasIcon,
        exCat: expCatIcon,
      },
    },

    {
      key: "payment",
      Icon: PaymentIcon,
      title: "Payment",
      displayName: "Payment",
      domAttributes: {
        "data-tour": "my-first-payment-step",
      },
    },
    {
      key: "employee",
      Icon: EmployeeIcon,
      domAttributes: {
        "data-tour": "my-first-employee-step",
      },
      title: (props) =>
        props?.activeRole === LoginRole.EMPLOYEE ? "Profile" : "Employee",
      displayName: (props) =>
        props?.activeRole === LoginRole.EMPLOYEE ? "Profile" : "Employee",
    },
    {
      key: "payroll",
      Icon: PayrollIcon,
      title: "Payroll",
      displayName: "Payroll",
      domAttributes: {
        "data-tour": "my-first-payroll-step",
      },
    },
    {
      key: "reimbursement",
      Icon: ReimbursementIcon,
      title: "Reimbursement",
      displayName: "Reimb.",
      domAttributes: {
        "data-tour": "my-first-reimbursement-step",
      },
    },
    {
      key: "compliance-new",
      Icon: ComplianceIcon,
      title: "Compliance",
      displayName: "Complianc.",
      domAttributes: {
        "data-tour": "my-first-compliance-step",
      },
    },

    // {
    //   key: "productMaster",
    //   Icon: PaymentIcon,
    //   title: "Product master",
    //   displayName: "Product mas",
    //   domAttributes: {
    //     "data-tour": "my-first-product-master-step",
    //   },
    // },

    // {
    //   key: "expenseCategory",
    //   Icon: PaymentIcon,
    //   title: "Expense category",
    //   displayName: "Exp. category",
    //   domAttributes: {
    //     "data-tour": "my-first-expense-category-step",
    //   },
    // },
  ];

  public static menuLinksLower: Array<MenuLink> = [
    {
      key: "synchronize",
      Icon: ({ isSyncing, ...props }: any) => (
        <SyncOutlined {...props} spin={isSyncing} />
      ),
      title: "Synchronize",
      displayName: "Sync.",
    },
    {
      key: "settings",
      Icon: SettingIcon,
      title: "Settings",
      displayName: "Settings",
    },
  ];

  private static _menuKeys: Array<string> = [];

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedMenu: "dashboard",
      showPasswordModal: false,
      passLoader: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      email: "",
      visibleSync: false,
      isSync: false,
      nameModalVisible: false,
      companyListModalVisible: false,
      updateList: false,
      openMenuKeys: [],
    };
    if (MainLayout._menuKeys.length === 0) {
      MainLayout._menuKeys = MainLayout.menuLinksUpper
        .map((m) => m.key)
        .concat(MainLayout.menuLinksLower.map((m) => m.key));
    }
  }

  static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State
  ): Partial<State> | null {
    const path = nextProps.location.pathname;
    const relPath = path.startsWith("/app") ? path.substr("/app/".length) : "";
    if (nextProps.currentStepKey === "profile") {
      return {
        openMenuKeys: ["user-submenu"],
      };
      // } else if (nextProps.currentStepKey === "invoice") {
      //   return {
      //     openMenuKeys: ["invoice"],
      //   };
      // } else if (nextProps.currentStepKey === "expense") {
      //   return {
      //     openMenuKeys: ["expense"],
      //   };
    } else if (
      nextProps.currentStepKey &&
      ["user-submenu", "invoice", "expense", "masters"].includes(
        prevState.openMenuKeys[0]
      )
    ) {
      return {
        openMenuKeys: [],
      };
    }
    if (relPath.length === 0) {
      return null;
    } else {
      let maxMatchKey = "";
      for (const menuKey of MainLayout._menuKeys) {
        if (menuKey === relPath) {
          maxMatchKey = menuKey;
          break;
        } else if (
          relPath.startsWith(menuKey) &&
          menuKey.length > maxMatchKey.length
        ) {
          maxMatchKey = menuKey;
        }
      }
      if (maxMatchKey !== prevState.selectedMenu) {
        return {
          selectedMenu: maxMatchKey,
        };
      }
      return null;
    }
  }

  componentDidMount() {
    const userData = localStorage.getItem("imt__user");
    const userParseData = JSON.parse(userData!);
    // const EndPoint = `wss://internal-management-portal.thetaone.review`;
    // const socket = socketIo(EndPoint, { transports: ["websocket"] });
    // socket.on("connect", () => {
    //   console.log("opopopop");
    // });

    // socket.on(`company/${userParseData.id}/etl`, (data) => {
    //   console.log(data, "++++++++++++++++++++++++++++");
    // });

    // socket.on("error", (err) => {
    //   console.log(err);
    // });
    // socket.on("disconect", (reason) => {
    //   console.log(reason);
    // });
    // socket.on("progress", (data: any) => {
    //   console.log(data);
    // });
    // socket.disconnect()

    // +++++++++++++++++++++++++++++++++++++++

    if (!this.props.userDetail.company_details) {
      localStorage.clear();
      window.location.pathname = "/";
    }
    if (!userParseData.first_name && !userParseData.last_name) {
      this.setState({
        nameModalVisible: true,
      });
    }
  }

  handleCompanyChange = async (companyId: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout293
    );

    const company = this.props.userDetail.company_details.find(
      (it: any) => it.id === companyId
    );
    if (company.name === this.props.userDetail.company_name) {
      notification.error({
        message: "Already login with this company",
      });
    } else {
      this.props.dochange({ company_id: companyId });
    }
  };

  handleCompanyPopup = (visible: boolean) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout294
    );

    this.setState({
      companyListModalVisible: visible,
    });
  };

  handleRestoreState = () => {
    this.setState({
      updateList: false,
    });
  };

  handleSwitch = (comId: any, role: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout295
    );

    this.props.dochange({ company_id: comId, role_id: role });
  };

  handleRoleChange = (el: any) => async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      `Role change (${el})`
    );

    const company = this.props.userDetail.company_details.find(
      (it: any) => it.name === this.props.userDetail.company_name
    );
    const { ok, json } = await fetchUtil("POST", "/access_change", {
      company_id: company.id,
      role_id: el,
    });
    if (ok) {
      localStorage.setItem("imt__token", json[0].token);
      this.props.setActiveRole({ role: json[0].user.role_ids });
      setTimeout(() => {
        if (el !== 2) {
          if (
            this.props.location.pathname === "/app/employee/me" ||
            this.props.location.pathname.includes("/app/reimbursement") ||
            this.props.location.pathname.includes("/app/payroll") ||
            this.props.location.pathname.includes("app/expiredMembership")
          ) {
            if (!userData.plan_purchased) {
              this.props.history.push(`/app/membership-plan`);
            } else {
              this.props.history.push(`/app`);
            }
          } else if (
            this.props.location.pathname === "/app/settings-role" ||
            this.props.location.pathname === "/app/settings-membership" ||
            this.props.location.pathname === "/app/synchronize/true" ||
            this.props.location.pathname === "/app/settings-users"
          ) {
            if (!userData.plan_purchased) {
              this.props.history.push(`/app/membership-plan`);
            } else {
              this.props.history.push(`/app`);
            }
          } else if (this.props.location.pathname === "/app/unauthorized") {
            this.props.history.push("/app/dashboard");
          } else {
            if (!userData.plan_purchased) {
              this.props.history.push(`/app/membership-plan`);
            } else {
              this.props.history.push(`${this.props.location.pathname}`);
            }
          }
        } else {
          if (!userData.plan_purchased) {
            this.props.history.push(`/app/expiredMembership`);
          } else {
            this.props.history.push(`/app/employee/me`);
          }
        }
      }, 100);
    } else {
      this.props.history.push(`/app/unauthorized`);
    }
  };

  handleMenuClick = (menu: MenuInfo) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout296
    );

    this.setState({
      openMenuKeys: [],
    });
    const key = menu.key;
    if (key === "employee" && this.props.activeRole === LoginRole.EMPLOYEE) {
      if (!userData.plan_purchased) {
        this.props.history.push(`/app/expiredMembership`);
      } else {
        this.props.history.push(`/app/${key}/me`);
      }
    } else if (key === "synchronize") {
      if (this.props.activeRole !== LoginRole.CA) {
        this.setState({ isSync: true }, () => {
          this.props.history.push(`/app/${key}/${this.state.isSync}`);
        });
      }
    } else {
      if (!userData.plan_purchased) {
        this.props.history.push(`/app/expiredMembership`);
      } else {
        this.props.history.push(`/app/${key}`);
      }
    }
  };

  handleMenuOpen: MenuOpenEventHandler = (keys) => {
    let newMenuKeys: Array<string> = [];
    if (Array.isArray(keys)) {
      newMenuKeys = keys.map((it) => it + "");
    } else {
      newMenuKeys = keys.open ? [keys.key + ""] : [];
    }
    const { openMenuKeys } = this.state;
    if (newMenuKeys.length > 0 || openMenuKeys.length > 0) {
      const submenuEventDetail =
        newMenuKeys.length === 0
          ? `close(${openMenuKeys.join(", ")})`
          : `open(${newMenuKeys.join(", ")})`;
      registerEvent(
        ga.EVENT_CATEGORY_BUTTON_CLICK,
        ga.EVENT_CLICK,
        `MainLayout>Menu>sub-menu ${submenuEventDetail}`
      );
    }
    // else it is click on menu without submenu
    this.setState({
      openMenuKeys: newMenuKeys,
    });
  };

  handleInvoice = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout297
    );

    if (!userData.plan_purchased) {
      this.props.history.push(`/app/expiredMembership`);
    } else {
      this.props.history.push(`/app/invoice`);
    }
  };
  handleVendor = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout297
    );

    if (!userData.plan_purchased) {
      this.props.history.push(`/app/expiredMembership`);
    } else {
      this.props.history.push(`/app/masters/vendor`);
    }
  };
  handleProductManager = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout297
    );

    if (!userData.plan_purchased) {
      this.props.history.push(`/app/expiredMembership`);
    } else {
      this.props.history.push(`/app/masters/productMaster`);
    }
  };
  handleExpenseCat = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout297
    );

    if (!userData.plan_purchased) {
      this.props.history.push(`/app/expiredMembership`);
    } else {
      this.props.history.push(`/app/masters/expenseCategory`);
    }
  };
  handleMembership = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout298
    );

    this.props.history.push(`/app/settings-membership`);
  };
  handleExpense = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout299
    );

    if (!userData.plan_purchased) {
      this.props.history.push(`/app/expiredMembership`);
    } else {
      this.props.history.push(`/app/expense`);
    }
  };
  handleLogoClick = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout300
    );

    if (!userData.plan_purchased) {
      this.props.history.push(`/app/expiredMembership`);
    } else {
      this.props.history.push("/app");
    }
  };

  handleCreditNote = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout301
    );

    if (!userData.plan_purchased) {
      this.props.history.push(`/app/expiredMembership`);
    } else {
      this.props.history.push("/app/invoice/creditNote");
    }
  };
  handleDebitNote = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout302
    );

    if (!userData.plan_purchased) {
      this.props.history.push(`/app/expiredMembership`);
    } else {
      this.props.history.push("/app/invoice/debitNote");
    }
  };
  handleePurchesDebit = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout303
    );

    if (!userData.plan_purchased) {
      this.props.history.push(`/app/expiredMembership`);
    } else {
      this.props.history.push("/app/expense/purchasedebitNote");
    }
  };
  handleSalesDebit = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout304
    );

    if (!userData.plan_purchased) {
      this.props.history.push(`/app/expiredMembership`);
    } else {
      this.props.history.push("/app/expense/sellerdebitNote");
    }
  };

  handleProfile = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout305
    );

    if (!userData.plan_purchased) {
      this.props.history.push(`/app/expiredMembership`);
    } else {
      this.props.history.push("/app/profile");
    }
  };

  handleRoleUser = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout306
    );

    if (!userData.plan_purchased) {
      this.props.history.push(`/app/expiredMembership`);
    } else {
      this.props.history.push("/app/settings-role");
    }
  };

  handleSettingUser = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout307
    );

    if (!userData.plan_purchased) {
      this.props.history.push(`/app/expiredMembership`);
    } else {
      this.props.history.push("/app/settings-users");
    }
  };
  handleSettingMembership = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout308
    );

    this.props.history.push("/app/settings-membership");
  };

  visibleAddClint = (visible: boolean) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout309
    );

    const queryParams = new URLSearchParams(this.props.history.location.search);
    if (visible) {
      queryParams.set("addClient", "1");
    } else {
      if (queryParams.has("addClient")) {
        queryParams.delete("addClient");
      }
    }
    this.props.history.push(
      this.props.history.location.pathname + "?" + queryParams.toString()
    );
  };

  handleLogout = () => {
    // if (this.props.syncInterval) {
    //   clearInterval(this.props.syncInterval);
    // }
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout310
    );

    // if (this.props.syncInterval) {
    //   clearInterval(this.props.syncInterval);
    // }
    localStorage.removeItem("imt_token");
    localStorage.removeItem("imt_user");
    localStorage.clear();
    localStorage.clear();
    this.props.history.push("/");
  };

  changePasswordModal = () => {
    this.setState({
      showPasswordModal: true,
    });
  };

  hidePasswordModal = () => {
    this.setState({
      showPasswordModal: false,
      passLoader: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      email: "",
    });
  };

  onVisibleChange = (visible: boolean) => {
    this.setState({
      nameModalVisible: visible,
    });
  };

  handleCurrentPasswordChange = (e: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout311
    );

    this.setState({ currentPassword: e.target.value });
  };

  handleNewPasswordChange = (e: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout312
    );

    this.setState({ newPassword: e.target.value });
  };

  handleConfirmPasswordChange = (e: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout313
    );

    this.setState({ confirmPassword: e.target.value });
  };

  handleEmailChange = (e: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout314
    );

    this.setState({ email: e.target.value });
  };

  updateNameInStore = (values: any) => {
    this.props.updateFirstLastName(values);
  };

  handleCompanyList = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout315
    );
    this.visibleAddClint(false);
    this.setState({
      updateList: this.props.updateCompanyList ? true : false,
    });
  };

  changePass = async (val: any) => {
    if (!val.currentPassword) {
      notification.error({
        message: "Enter current password",
      });
      return;
    }

    if (!PASSWORD_VALIDATION_REGEX.test(val.newPassword)) {
      notification.error({
        message: "Please enter a strong and valid password",
      });
      return;
    }

    if (!val.newPassword) {
      notification.error({
        message: "Enter new password",
      });
      return;
    }
    if (!val.confirmPassword) {
      notification.error({
        message: "Enter confirm password",
      });
      return;
    }
    if (val.newPassword !== val.confirmPassword) {
      notification.error({
        message: "New and confirm password must be same",
      });
      return;
    }
    try {
      this.setState({
        passLoader: true,
      });
      const { ok, message } = await changePassword(
        // this.state.email,
        val.currentPassword,
        val.newPassword
      );

      if (ok) {
        notification.success({
          message,
        });
        this.setState({
          showPasswordModal: false,
          passLoader: false,
        });
      } else {
        notification.error({
          message: "Unable to change password",
        });
        this.setState({
          passLoader: false,
        });
      }
    } catch (err) {
      notification.error({
        message: "Error",
      });
    }
  };

  render() {
    const otherMenuProps = this.props.showSyncButtonTour
      ? { openKeys: this.state.openMenuKeys }
      : {};
    // const { company_name } = this.props.userDetail;
    // const companyDisplayName =
    //   company_name?.length > 18
    //     ? company_name.substr(0, 18) + "..."
    //     : company_name;
    const queryParams = new URLSearchParams(this.props.location.search);
    const showAddClientModal = queryParams.get("addClient") === "1";
    return (
      <Layout>
        {window.location.pathname.includes("membership-plan") ? (
          <></>
        ) : (
          <Sider
            className={styles.sider}
            theme="light"
            trigger={null}
            collapsible
            defaultCollapsed
            collapsedWidth={56}
          >
            <Menu
              triggerSubMenuAction="click"
              className={styles.menu}
              mode="inline"
              selectedKeys={[this.state.selectedMenu]}
              onOpenChange={this.handleMenuOpen}
              {...otherMenuProps}
            >
              <Menu.SubMenu
                key={"user-submenu"}
                className={styles.userSubmenu}
                popupClassName={`${styles.userSubmenuPopup} my-first-profile-step`}
                icon={
                  <div className={styles.userPic}>
                    {this.props.userFirstChar}
                  </div>
                }
              >
                <Menu.Item
                  className={styles.userSubmenuLvl2}
                  style={{
                    backgroundColor: "#2F80ED",
                    marginTop: 0,
                    height: this.props.userDetail.first_name ? 78 : 48,
                    color: "white",
                    fontWeight: 700,
                  }}
                >
                  <div className={styles.divProfile}>
                    {this.props.userDetail.first_name ? (
                      <span style={{ marginTop: "40px" }}>
                        <p className={styles.nameP}>
                          {this.props.userDetail.first_name +
                            " ".concat(this.props.userDetail.last_name)}
                        </p>
                      </span>
                    ) : null}

                    <span>
                      <p
                        className={
                          this.props.userDetail.first_name
                            ? styles.emailSpan
                            : styles.emailSpan2
                        }
                      >
                        {this.props.userDetail.email}
                      </p>
                    </span>
                  </div>
                </Menu.Item>
                <Menu.Item
                  className={styles.curCompanySubMenu}
                  // popupClassName={styles.userSubmenuPopup}
                  icon={<InsertRowLeftOutlined />}
                  onClick={() => this.handleCompanyPopup(true)}
                >
                  Company list
                  {/* {this.props.activeRole === LoginRole.EMPLOYEE
                  ? companyDisplayName
                  : // : `Company List (${this.props.companyCount})`}
                    `Company List`} */}
                  {/* {`${
                  this.props.userDetail.company_name?.substr(0, 18) +
                  (this.props.userDetail.company_name?.length > 18 ? "..." : "")
                }`} */}
                </Menu.Item>

                <Menu.SubMenu
                  className={styles.userSubmenuLvl2}
                  popupClassName={styles.userSubmenuPopup}
                  icon={<AuditOutlined />}
                  // title={this.props.activeRole}
                  title={
                    this.props.activeRole === 1
                      ? "Admin"
                      : this.props.activeRole === 2
                      ? "Employee"
                      : this.props.activeRole === 3
                      ? "CA"
                      : this.props.activeRole === 4
                      ? "Delegate user"
                      : "Superuser"
                  }
                >
                  {this.props.userDetail.role_ids.map((el: any) => {
                    return (
                      <Menu.Item
                        key={el}
                        className={`bail ${styles.userSubmenuLvl3}`}
                        onClick={this.handleRoleChange(el)}
                      >
                        {el === 1
                          ? "Admin"
                          : el === 2
                          ? "Employee"
                          : el === 3
                          ? "CA"
                          : el === 4
                          ? "Delegate user"
                          : "Superuser"}
                      </Menu.Item>
                    );
                  })}

                  {/*
                <Menu.Item key="8">Option 8</Menu.Item> */}
                </Menu.SubMenu>
                {this.props.activeRole !== LoginRole.EMPLOYEE ? (
                  <Menu.Item
                    className={styles.userSubmenuLvl2}
                    icon={<UserAddOutlined />}
                    onClick={() => this.visibleAddClint(true)}
                    disabled={
                      userData.added_clients >= userData.client_no ||
                      (userData.activeRole === LoginRole.CA &&
                        !userData.plan_purchased) ||
                      userData.primary_associated_company_id === null
                    }
                  >
                    Add client
                  </Menu.Item>
                ) : null}

                {this.props.activeRole === LoginRole.ADMIN ||
                this.props.activeRole === LoginRole.SUPERuSER ? (
                  <Menu.Item
                    className={styles.userSubmenuLvl2}
                    icon={<UserOutlined twoToneColor="black" />}
                    onClick={this.handleProfile}
                    data-tour="my-first-profile-step"
                  >
                    Profile
                  </Menu.Item>
                ) : null}
                {this.props.activeRole === LoginRole.EMPLOYEE ||
                this.props.activeRole === LoginRole.ADMIN ||
                this.props.activeRole === LoginRole.SUPERuSER ||
                this.props.activeRole === LoginRole.CA ? (
                  <Menu.Item
                    className={`${styles.changePassMenuItem} bail`}
                    onClick={this.changePasswordModal}
                    icon={<KeyOutlined />}
                    // disabled={!userData.membership_plan ? true : false}
                  >
                    Change password
                  </Menu.Item>
                ) : null}

                {userData.primary_associated_company_id !== null ? (
                  <>
                    <Menu.Item
                      className={`${styles.changePassMenuItem} bail`}
                      onClick={this.handleMembership}
                      icon={<LockOutlined />}
                      disabled={
                        this.props.activeRole === LoginRole.EMPLOYEE ||
                        this.props.activeRole === LoginRole.CA
                      }
                    >
                      Membership
                    </Menu.Item>
                    <span style={{ margin: "4px 0 0 35px", display: "flex" }}>
                      <FieldInfo text={userData.plan_name!} />
                    </span>
                  </>
                ) : null}

                <Menu.Item
                  className={styles.userSubmenuLvl2}
                  icon={<LogoutOutlined />}
                  onClick={this.handleLogout}
                >
                  Log out
                </Menu.Item>
              </Menu.SubMenu>

              {MainLayout.menuLinksUpper
                .filter((menu) => {
                  if (
                    this.props.activeRole === LoginRole.ADMIN ||
                    this.props.activeRole === LoginRole.SUPERuSER
                  ) {
                    return true;
                  } else if (this.props.activeRole === LoginRole.EMPLOYEE) {
                    return EMPLOYEE_MENU_KEYS.includes(menu.key);
                  } else if (this.props.activeRole === LoginRole.CA) {
                    return true;
                  } else {
                    return false;
                  }
                })
                .map((menu) => {
                  const displayName =
                    typeof menu.displayName === "function"
                      ? menu.displayName(this.props)
                      : menu.displayName;
                  return menu.key === "invoice" ? (
                    <Menu.SubMenu
                      data-tour="my-first-invoice-step"
                      className={styles.userSubmenuInvoice}
                      key={menu.key}
                      // disabled={!userData.membership_plan}
                      popupClassName={`${styles.userSubmenuPopupInvoice} ${styles.invoiceMenuGroup}`}
                      icon={
                        <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <menu.Icon
                            className={styles.icon}
                            highlighted={this.state.selectedMenu === menu.key}
                          />
                          <span className={styles.menuItemCss}>
                            {displayName}
                          </span>
                        </div>
                      }
                    >
                      <Menu.ItemGroup
                        key={menu.key}
                        className={`${styles.invoiceMenuGroup} my-first-invoice-step`}
                      >
                        <Menu.Item
                          key={"SalesInvoice"}
                          onClick={this.handleInvoice}
                          className={`${styles.changePassMenuItem} bail`}
                          icon={
                            <span className={styles.subMenuInvoiceIcon}>
                              <menu.subMenuInvoice.in />
                            </span>
                          }
                        >
                          <div className={styles.subMenuInvoiceLabel}>
                            Invoice
                          </div>
                        </Menu.Item>
                        <Menu.Item
                          key={"SalesCreditNote"}
                          className={`${styles.changePassMenuItem} bail`}
                          onClick={this.handleCreditNote}
                          icon={
                            <span className={styles.subMenuInvoiceIcon}>
                              <menu.subMenuInvoice.cn
                              //  highlighted={true}
                              />
                            </span>
                          }
                        >
                          <div className={styles.subMenuInvoiceLabel}>
                            {" "}
                            Credit note
                          </div>
                        </Menu.Item>

                        <Menu.Item
                          key={"SalesDebitNote"}
                          className={`${styles.changePassMenuItem} bail`}
                          onClick={this.handleDebitNote}
                          icon={
                            <span className={styles.subMenuInvoiceIcon}>
                              <menu.subMenuInvoice.dn />
                            </span>
                          }
                          // style={{ height: "30px" }}
                        >
                          <div className={styles.subMenuInvoiceLabel}>
                            {" "}
                            Debit note
                          </div>
                        </Menu.Item>
                      </Menu.ItemGroup>
                    </Menu.SubMenu>
                  ) : menu.key === "expense" ? (
                    <Menu.SubMenu
                      data-tour="my-first-expense-step"
                      className={styles.userSubmenuInvoice}
                      key={menu.key}
                      // disabled={!userData.membership_plan}
                      popupClassName={`${styles.userSubmenuPopupInvoice} my-first-expense-step`}
                      icon={
                        <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <menu.Icon
                            className={styles.icon}
                            highlighted={this.state.selectedMenu === menu.key}
                          />
                          <span className={styles.menuItemCss}>
                            {displayName}
                          </span>
                        </div>
                      }
                    >
                      <Menu.ItemGroup
                        key={menu.key}
                        className={styles.invoiceMenuGroup}
                      >
                        <Menu.Item
                          key={"purches"}
                          onClick={this.handleExpense}
                          className={`${styles.changePassMenuItem} bail`}
                          icon={
                            <span className={styles.subMenuInvoiceIcon}>
                              <menu.subMenuExpense.ex />
                            </span>
                          }
                        >
                          <div className={styles.subMenuInvoiceLabel}>
                            Expense
                          </div>
                        </Menu.Item>
                        <Menu.Item
                          key={"SalesCreditNote"}
                          className={`${styles.changePassMenuItem} bail`}
                          onClick={this.handleePurchesDebit}
                          icon={
                            <span className={styles.subMenuInvoiceIcon}>
                              <menu.subMenuExpense.pdb />
                            </span>
                          }
                        >
                          <div className={styles.subMenuInvoiceLabel}>
                            Supplier credit note{" "}
                          </div>
                        </Menu.Item>

                        <Menu.Item
                          key={"SalesDebitNote"}
                          className={`${styles.changePassMenuItem} bail`}
                          onClick={this.handleSalesDebit}
                          icon={
                            <span className={styles.subMenuInvoiceIcon}>
                              <menu.subMenuExpense.sdb />
                            </span>
                          }
                          // style={{ height: "30px" }}
                        >
                          <div className={styles.subMenuInvoiceLabel}>
                            Supplier debit note
                          </div>
                        </Menu.Item>
                      </Menu.ItemGroup>
                    </Menu.SubMenu>
                  ) : menu.key === "masters" ? (
                    <Menu.SubMenu
                      data-tour="my-first-masters-step"
                      className={styles.userSubmenuInvoice}
                      key={menu.key}
                      popupClassName={`${styles.userSubmenuPopupInvoice} my-first-masters-step`}
                      icon={
                        <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <menu.Icon
                            className={styles.icon}
                            highlighted={this.state.selectedMenu === menu.key}
                          />
                          <span className={styles.menuItemCss}>
                            {displayName}
                          </span>
                        </div>
                      }
                    >
                      <Menu.ItemGroup
                        key={menu.key}
                        className={styles.invoiceMenuGroup}
                      >
                        <Menu.Item
                          key={"vendor"}
                          onClick={this.handleVendor}
                          className={`${styles.changePassMenuItem} bail`}
                          icon={
                            <span className={styles.subMenuInvoiceIcon}>
                              <menu.subMenuMaster.vdIcon />
                            </span>
                          }
                        >
                          <div className={styles.subMenuInvoiceLabel}>
                            {" "}
                            Vendor / Customer
                          </div>
                        </Menu.Item>
                        <Menu.Item
                          key={"productMaster"}
                          className={`${styles.changePassMenuItem} bail`}
                          onClick={this.handleProductManager}
                          icon={
                            <span className={styles.subMenuInvoiceIcon}>
                              <menu.subMenuMaster.pm />
                            </span>
                          }
                        >
                          <div className={styles.subMenuInvoiceLabel}>
                            {" "}
                            Products
                          </div>
                        </Menu.Item>

                        <Menu.Item
                          key={"expenseCategory"}
                          className={`${styles.changePassMenuItem} bail`}
                          onClick={this.handleExpenseCat}
                          icon={
                            <span className={styles.subMenuInvoiceIcon}>
                              <menu.subMenuMaster.exCat />
                            </span>
                          }
                          style={{ height: "30px" }}
                        >
                          <div className={styles.subMenuInvoiceLabel}>
                            {" "}
                            Expense categories
                          </div>
                        </Menu.Item>
                      </Menu.ItemGroup>
                    </Menu.SubMenu>
                  ) : (
                    <Menu.Item
                      key={menu.key}
                      className={styles.menuItem}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      icon={
                        <div style={{ lineHeight: "8px" }}>
                          <menu.Icon
                            className={styles.icon}
                            highlighted={this.state.selectedMenu === menu.key}
                          />
                        </div>
                      }
                      title={menu.title}
                      // disabled={!userData.membership_plan}
                      onClick={this.handleMenuClick}
                      {...menu.domAttributes}
                    >
                      <span className={styles.menuItemCss}>{displayName}</span>
                    </Menu.Item>
                  );
                })}
              <li className={styles.expander} />
              {MainLayout.menuLinksLower
                .filter((menu) => {
                  if (
                    this.props.activeRole === LoginRole.ADMIN ||
                    this.props.activeRole === LoginRole.SUPERuSER
                  ) {
                    return true;
                  } else if (this.props.activeRole === LoginRole.EMPLOYEE) {
                    return EMPLOYEE_MENU_KEYS.includes(menu.key);
                  } else if (this.props.activeRole === LoginRole.CA) {
                    return true;
                  } else {
                    return false;
                  }
                })
                .map((menu) =>
                  menu.key !== "synchronize" ? (
                    this.props.activeRole === LoginRole.CA ? (
                      <Menu.Item
                        key={menu.key}
                        className={styles.lowerMenuItem}
                        // disabled={!userData.membership_plan}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                        icon={
                          <div style={{ lineHeight: "8px" }}>
                            <menu.Icon
                              className={styles.icon}
                              highlighted={this.state.selectedMenu === menu.key}
                            />
                          </div>
                        }
                        title={menu.title}
                      >
                        <span className={styles.belowMenuItemCss}>
                          {menu.displayName}
                        </span>
                      </Menu.Item>
                    ) : (
                      // settings
                      // <></>
                      <Menu.SubMenu
                        data-tour="my-first-settings-step"
                        key={menu.key}
                        // disabled={!userData.membership_plan}
                        /* className={styles.lowerMenuItem}
                      popupClassName={styles.userSubmenuPopup} */
                        className={styles.lowerMenuItem}
                        popupClassName={styles.userSubmenuPopup}
                        icon={
                          <div style={{ lineHeight: "8px" }}>
                            <menu.Icon
                              className={styles.icon}
                              highlighted={this.state.selectedMenu === menu.key}
                            />
                          </div>
                        }
                        title={
                          <div
                            className={styles.belowSettingsMenuItemCss}
                            style={{ textAlign: "center" }}
                          >
                            {menu.title}
                          </div>
                        }
                      >
                        <Menu.Item
                          onClick={this.handleRoleUser}
                          className={styles.userSubmenuLvl2}
                          icon={<LockOutlined />}
                        >
                          Role
                        </Menu.Item>
                        <Menu.Item
                          onClick={this.handleSettingUser}
                          className={styles.userSubmenuLvl2}
                          icon={<UserOutlined />}
                        >
                          Users
                        </Menu.Item>
                        {/* <Menu.Item
                        onClick={this.handleMembership}
                        className={styles.userSubmenuLvl2}
                        icon={<IdcardOutlined />}
                      >
                        Membership
                      </Menu.Item> */}
                      </Menu.SubMenu>
                    )
                  ) : (
                    // synchronize
                    <Menu.Item
                      data-tour="my-first-sidebar-step"
                      key={menu.key}
                      className={styles.lowerMenuItem}
                      // disabled={!userData.membership_plan}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      icon={
                        <div style={{ lineHeight: "8px" }}>
                          <menu.Icon
                            className={styles.icon}
                            highlighted={
                              this.state.selectedMenu === menu.key
                                ? true
                                : undefined
                            }
                            isSyncing={this.props.isSyncing}
                          />
                        </div>
                      }
                      title={menu.title}
                      onClick={this.handleMenuClick}
                    >
                      <span className={styles.belowMenuItemCss}>
                        {menu.displayName}
                      </span>
                    </Menu.Item>
                  )
                )}

              <Menu.Item
                // data-tour="my-second-sidebar-step"
                style={{
                  marginBottom: "0px",
                  backgroundColor: "var(--processing-color)",
                }}
                onClick={this.handleLogoClick}
                icon={
                  <Tooltip
                    title={`UI version v${pkg.version}`}
                    placement="right"
                  >
                    <img
                      className={styles.logoMenu}
                      src="/logo-white@2x.png"
                      alt="logo"
                    />
                  </Tooltip>
                }
              />
            </Menu>
          </Sider>
        )}

        <ChangePasswordDialog
          visible={this.state.showPasswordModal}
          changePass={this.changePass}
          hidePasswordModal={this.hidePasswordModal}
          passLoader={this.state.passLoader}
        />
        {this.props.children}
        {this.state.nameModalVisible ? (
          <FirstNameModal
            visible={this.state.nameModalVisible}
            onVisibleChange={this.onVisibleChange}
            updateNameInStore={this.updateNameInStore}
          />
        ) : null}

        {this.state.companyListModalVisible ? (
          <CompanyListModal
            visible={this.state.companyListModalVisible}
            onVisibleCHange={this.handleCompanyPopup}
            userInfo={this.props.userDetail}
            activeRole={this.props.activeRole}
            handleAddCliantPopup={this.visibleAddClint}
            handleCompanyChange={this.handleCompanyChange}
            handleSwitch={this.handleSwitch}
            updateList={this.props.updateCompanyList}
            handleRestoreState={this.handleRestoreState}
            handleUpdateCompanyList={this.props.handleUpdateCompanyList}
          />
        ) : null}
        {showAddClientModal ? (
          <AddClientDrawer
            isOpen={showAddClientModal}
            onClose={this.visibleAddClint}
            updateCompanyList={this.handleCompanyList}
          />
        ) : null}
        <div
          className={styles.submitBtn}
          // type="primary"
          // htmlType="submit"
          // size="large"
          style={{
            position: "fixed",
            bottom: 70,
            right: 24,
            // Add margin for spacing
          }}
          // loading={this.props.loading}
          // disabled={isSelectingRoles}
        >
          <Tawkto />
        </div>
      </Layout>
    );
  }
}

export default connector(withRouter(MainLayout));
