import React, { PureComponent } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Form, Input, Button, Modal, Row, notification, Divider } from "antd";
import { FormInstance } from "antd/lib/form";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { StoreState, action } from "./store";
import { forgotPassword, LoginRole, LoginRequest } from "~/api/auth";

import styles from "./LoginModal.module.css";
import { EMAIL_VALIDATION_REGEX } from "~/lib/constants";
import { GoogleLogin } from "react-google-login";
import { registerEvent } from "~/analytics";
import {
  events,
  EVENT_CATEGORY_BUTTON_CLICK,
  EVENT_CLICK,
} from "~/contants/gaConstants";

import * as ga from "~/contants/gaConstants";

const mapStateToProps = (state: StoreState) => ({
  open: state.login.showModal,
  loading: state.login.apiState === "loading",
  errorMsg: state.login.apiState === "error" ? state.login.errorMsg : "",
  availableRoles: state.login.user?.role_ids,
});
const mapDispatchToProps = {
  hideModal: action.login.hideModal,
  doLogin: action.login.doLogin,
  // setActiveRole: action.login.setActiveRole,
  // setrole:action.login.removeRole,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

// const userData: any = localStorage.getItem("imt__x_user");
// const ud = JSON.parse(userData);

interface Props extends PropsFromRedux {
  doNotUseMe?: never;
}
interface State {
  forgotPassModal: boolean;
  forgotEmail: any;
  frgtPassloading: boolean;
  isSelectingRoles: boolean;
  visibleSubmit: boolean;
  googleLoginDisable: boolean;
  logoutReason?: string | null;
}

type Values = {
  email: string;
  password: string;
  activeRole?: LoginRole;
};

export class LoginModal extends PureComponent<Props, State> {
  _formRef?: FormInstance<Values>;

  state: State = {
    forgotPassModal: false,
    forgotEmail: "",
    frgtPassloading: false,
    isSelectingRoles: false,
    visibleSubmit: false,
    googleLoginDisable: false,
  };

  onFormRef = (instance?: FormInstance<Values> | null) => {
    this._formRef = instance || undefined;
  };

  onFinish = (values: Values) => {
    // const isSelectingRoles = !!this.props.availableRoles;
    // console.log(ud.);
    // if (ud !== null) {
    //   if (isSelectingRoles) {
    //     if (!values.activeRole) {
    // if (ud) {
    //   if (ud.role_ids.length > 1) {
    //     if (!values.activeRole) {
    //       notification.error({ message: "Please select role" });
    //       return;
    //     }
    //   }
    // }

    // }
    //     return;
    //   }
    // }

    // if (!!this.props.availableRoles) {
    //   // this.props.setActiveRole({ role: values.activeRole! });
    // } else {
    this.props.doLogin(values);
    // }
  };

  hideForgotPasswordModal = () => {
    registerEvent(
      EVENT_CATEGORY_BUTTON_CLICK,
      EVENT_CLICK,
      events.loginModalHideForgot
    );

    this.setState({
      forgotPassModal: false,
    });
  };

  showForgotPassModal = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.loginModal1
    );

    this.setState(
      {
        forgotPassModal: true,
      },
      () => {
        this.props.hideModal();
      }
    );
  };

  forgotPass = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.loginModal2
    );

    if (!this.state.forgotEmail) {
      notification.error({
        message: "Please enter email",
      });
    }
    if (!EMAIL_VALIDATION_REGEX.test(this.state.forgotEmail)) {
      notification.error({
        message: "Please a valid email",
      });
      return;
    }
    if (this.state.forgotEmail) {
      this.setState({
        frgtPassloading: true,
      });
      try {
        const { ok, message } = await forgotPassword(this.state.forgotEmail);

        if (ok) {
          notification.success({
            message,
          });
          this.setState({
            forgotPassModal: false,
            frgtPassloading: false,
          });
        } else {
          notification.error({
            message,
          });
          this.setState({
            frgtPassloading: false,
          });
        }
      } catch (err) {
        notification.error({
          message: "Error",
        });
      }
    }
  };

  handleForgotEmail = (e: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.loginModal3
    );

    this.setState({
      forgotEmail: e.target.value,
    });
  };

  onValuesChange = (changedValues: any, values: any) => {
    if (changedValues.email) {
      localStorage.clear();
    }
  };

  responseGoogle = (response: any) => {
    if (response) {
      const loginpayLoad: LoginRequest = {
        email: response.profileObj.email,
        password: null,
        google_login: true,
        google_secret_key: response.tokenId,
      };
      this.props.doLogin(loginpayLoad);
    }
  };

  handleFailAuthGoogle = (response: any) => {
    let errorMsg = "";
    if (response && response.error === "popup_closed_by_user") {
      this.setState({ googleLoginDisable: false });
    }
    if (response && response.error === "idpiframe_initialization_failed") {
      this.setState({ googleLoginDisable: true });
      errorMsg =
        "Oops. Google login is not available. Check if you are in incognito mode";
      notification.error({ message: errorMsg });
    } else {
      errorMsg = "Login unsuccessfull";
      notification.error({ message: errorMsg });
    }

    this.setState({
      visibleSubmit: true,
    });
  };

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    if (query.has("reason")) {
      this.setState({
        logoutReason: query.get("reason"),
      });
    }
  }

  render() {
    // const isSelectingRoles = ud?.role_ids.length > 1 ? true : false;
    let errorMsg = this.props.errorMsg;
    if (!errorMsg && this.state.logoutReason === "session_expired") {
      errorMsg = "Session expired, please login again.";
    }
    return (
      <>
        <Modal
          width={420}
          title="Sign in"
          onCancel={this.props.hideModal}
          visible={this.props.open}
          destroyOnClose
          footer={null}
          centered
          maskClosable={false}
        >
          <div>
            <Form
              className={styles.form}
              ref={this.onFormRef}
              onFinish={this.onFinish}
              onValuesChange={this.onValuesChange}
            >
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as any}
                // tslint:disable-next-line: jsx-no-lambda
                // uxMode="redirect"
                render={(renderProps) => (
                  <Button
                    className={styles.submitBtn}
                    size="large"
                    style={{ marginTop: "7px" }}
                    disabled={this.state.googleLoginDisable}
                    onClick={renderProps.onClick}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="35"
                        height="35"
                        style={{
                          marginRight: "10px",
                        }}
                        preserveAspectRatio="xMidYMid"
                        viewBox="0 0 256 400"
                      >
                        <path
                          fill="#4285F4"
                          d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                        />
                        <path
                          fill="#34A853"
                          d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                        />
                        <path
                          fill="#FBBC05"
                          d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                        />
                        <path
                          fill="#EB4335"
                          d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                        />
                      </svg>
                      <p
                        style={{
                          justifySelf: "center",
                        }}
                      >
                        Login with Google{" "}
                      </p>
                    </div>
                  </Button>
                )}
                onSuccess={this.responseGoogle}
                onFailure={this.handleFailAuthGoogle}
                cookiePolicy={"single_host_origin"}
              />
              <Divider plain>OR</Divider>
              <Form.Item name="email" label="Email" required>
                <Input
                  type="email"
                  placeholder="Email id"
                  allowClear
                  size="large"
                  autoFocus
                  prefix={<MailOutlined />}
                  autoComplete="email"
                />
              </Form.Item>
              <Form.Item name="password" label="Password" required>
                <Input.Password
                  size="large"
                  placeholder="Password"
                  prefix={<LockOutlined />}
                  autoComplete="current-password"
                  // disabled={isSelectingRoles}
                />
              </Form.Item>
              <Button
                className={styles.submitBtn}
                type="primary"
                htmlType="submit"
                size="large"
                loading={this.props.loading}
                // disabled={isSelectingRoles}
              >
                {!!this.props.errorMsg ? "Retry" : "Submit"}
              </Button>

              <Row>
                <span
                  className={styles.frgetPass}
                  onClick={this.showForgotPassModal}
                >
                  Forgot Password
                </span>
              </Row>
            </Form>
            <div className={styles.errorSpace}>{errorMsg}</div>
          </div>
        </Modal>
        <Modal
          width={420}
          title="Forgot Password"
          maskClosable={false}
          footer={
            <>
              <Button color="red" onClick={this.hideForgotPasswordModal}>
                Cancel
              </Button>
              <Button
                type="primary"
                loading={this.state.frgtPassloading}
                onClick={this.forgotPass}
              >
                Ok
              </Button>
            </>
          }
          onCancel={this.hideForgotPasswordModal}
          visible={this.state.forgotPassModal}
          destroyOnClose={true}
          centered
        >
          <span className={styles.frgetPasslabel}>Please enter the email</span>
          <Input
            type="email"
            placeholder={"e.g. youremail@domain.com"}
            value={this.state.forgotEmail}
            onChange={this.handleForgotEmail}
          />
        </Modal>
      </>
    );
  }
}

export default connector(LoginModal);
